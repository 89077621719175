.about .card {
  -webkit-box-shadow: 10px 10px 19px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 10px 10px 19px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 10px 10px 19px 0px rgba(0, 0, 0, 0.75);
}

.about .card-img-top {
  height: 350px;
}

.about .card-img-top:hover {
  cursor: pointer;
}

.about .card-body {
  padding: 0.5rem 0.7rem 0.2rem 0.7rem;
}
