select {
  width: 30%;
}

@media screen and (min-width: 992px) {
  .position-lg-absolute {
    position: absolute;
  }

  .row-count-container select {
    width: 25% !important;
    margin-left: 0.5rem;
    z-index: 1;
  }
}

.card-footer {
  overflow-y: scroll;
  max-height: 8rem;
}
