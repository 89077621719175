* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  font-size: 18px;
}

img,
picture,
video,
svg {
  display: block;
  width: 100%;
  height: auto;
}
