.faq {
  position: relative;
  background-color: rgb(109, 179, 242);
}

.faq::before {
  position: absolute;
  content: "";
  top: -120px;
  left: 50%;
  transform: translateX(-50%);
  height: 250px;
  width: 100%;
  background-color: rgb(109, 179, 242);
  z-index: -1;

  border-radius: 10%;
}
