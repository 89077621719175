h3 {
  text-decoration: underline;
}

.form-label {
  min-width: fit-content !important;
}

.contact-form {
  max-width: 1200px;
  margin: auto;
}
