.accordion {
  --padding-y: 10px;
  --padding-x: 20px;

  width: 100%;
  max-width: 1250px;
  margin: auto;
  background-color: black;
  color: white;
  border-radius: 25px;

  text-align: center;
}

.accordion .dropdown:hover {
  cursor: pointer;
}
.accordion .dropdown:not(:last-of-type) {
  border-bottom: 1px solid white;
}

.accordion .question {
  position: relative;
  padding: 0 var(--padding-x);
  display: flex;
  align-items: center;
  margin-top: var(--padding-y) 0;
  padding: var(--padding-y) 0;
}

.accordion .question p {
  width: 95%;
  margin: 0;
  text-transform: capitalize;
}

.accordion .question svg {
  width: 5%;
  justify-self: flex-end;
}

.accordion .answer {
  height: 0;
  background-color: white;
  color: black;
  overflow: hidden;
  padding: 0 calc(var(--padding-x) / 2);
}

.accordion .dropdown:last-of-type .answer {
  border-radius: 25px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.accordion .answer.active {
  padding-top: var(--padding-y);
}
