*::-webkit-scrollbar {
  height: 0.5rem;
}

*::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 2rem;
}

*::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
  border-radius: 2rem;
}

body {
  font-family: "Roboto Slab", serif;
}

.container {
  max-width: 1800px;
}

ul,
li {
  padding: 0;
  margin: 0;
}
