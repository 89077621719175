.landing {
  margin: auto;
  background-color: #b0d7f0;
  padding: 0 5%;

  background-image: linear-gradient(to right, rgba(109, 179, 242, 0.6), rgba(109, 179, 242, 0.6)),
    url("../../public/images/landing_background.jpg");
  background-size: cover;
  height: 100svh;
}

.landing > div:first-of-type {
  height: auto;
}

.landing-image {
  margin-left: -40px;
  width: 70%;
}

.landing h1 {
  font-size: 2.6rem;
}

.landing ul {
  list-style: none;
}

@media screen and (min-width: 992px) {
  .landing > div:first-of-type {
    height: inherit;
  }

  .landing h1 {
    max-width: 1200px;
    font-size: 3.5rem;
  }

  .landing-image {
    max-width: 550px;
    margin-left: 100px;
  }

  .landing span {
    font-size: 2rem;
    font-weight: 300;
  }
}
